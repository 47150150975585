import React from "react";

const EditableRow = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
}) => {
  return (
    <tr>
      <td>
        <select
          type="text"
          required="required"
          placeholder="S&P 500 E-Mini"
          name="assetName"
          value={editFormData.assetName}
          onChange={handleEditFormChange}
        >
            <option value="S&P 500 E-Mini">S&P 500 E-Mini</option>
            <option value="Nasdaq 100 E-mini">Nasdaq 100 E-mini</option>
            <option value="US Treasury Bond">US Treasury Bond</option>
            <option value="Gold">Gold</option>
            <option value="Silver">Silver</option>
            <option value="RBOB Gas">RBOB Gas</option>
            <option value="Bitcoin">Bitcoin</option>
            <option value="Ethereum">Ethereum</option>

        </select>
      </td>
      <td>
        <select
          type="text"
          required="required"
          placeholder="Long"
          name="direction"
          value={editFormData.direction}
          onChange={handleEditFormChange}
        >
            <option value="Long" selected>Long</option>
            <option value="Short">Short</option>
        </select>
      </td>
      <td>
        <input
          type="text"
          required="required"
          placeholder="Contracts:"
          name="contracts"
          value={editFormData.contracts}
          onChange={handleEditFormChange}
        ></input>
      </td>
      <td>
        <input
          type="riskBudget"
          required="required"
          placeholder="Risk Budget:"
          name="riskBudget"
          value={editFormData.riskBudget}
          onChange={handleEditFormChange}
        ></input>
      </td>
      <td>
        <button type="submit">Save</button>
        <button type="button" onClick={handleCancelClick}>
          Cancel
        </button>
      </td>
    </tr>
  );
};

export default EditableRow;