import './App.css';
import LineChart from './components/LineChart';
import ReadOnlyRow from "./components/ReadOnlyRow";
import EditableRow from "./components/EditableRow";
import React, { useState, useEffect, Fragment } from "react";
import axios from 'axios';
import { nanoid } from "nanoid";


function App() {

  // Default parameters
  const default_interval = "30m";
  const default_window = 500;
  const default_portfolio = [{"id":1,"assetName":"S&P 500 E-Mini","direction":"Long","contracts":"1","riskBudget":"5000"},
                             {"id":2,"assetName":"Gold","direction":"Long","contracts":"1","riskBudget":"5000"}];

  // Set state variables
  const [interval, setInterval] = useState(default_interval);
  const [window, setWindow] = useState(default_window);
  const [portfolio, setPortfolio] = useState(default_portfolio);
  const [metrics, setMetrics] = useState([]);

  function updateWindow(newWindow) {
    setWindow(newWindow.target.value)
  };

  function updateInterval(newInterval) {
    setInterval(newInterval.target.value)
  };

  function submitPortfolio() {
    postPortfolio(interval, window, portfolio);
  };

  function updateMetrics(data) {
    setMetrics(data)
  };

  function postPortfolio(interval, window, portfolio) {

    let input_data = JSON.stringify({
      interval:interval,
      window:parseFloat(window),
      portfolio:portfolio
    });

    const resp = axios.post("https://cryptic-peak-18087.herokuapp.com/https://yndh4y407d.execute-api.us-east-2.amazonaws.com/v1/portfolio-metrics", input_data)
    .then(res => {
      console.log(res.data.risk_compression)
      updateMetrics(res.data);
    })

    .catch(err => console.log(err))

  };



  /**
   * New code changes...
   */
   const [addFormData, setAddFormData] = useState({
    assetName: "",
    direction: "",
    contracts: "",
    riskBudget: "",
  });


  const [editFormData, setEditFormData] = useState({
    assetName: "",
    direction: "",
    contracts: "",
    riskBudget: "",
  });


  const [editPortfolioId, setEditPortfolioId] = useState(null);


  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };


  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };


  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    const newPortfolio = {
      id: nanoid(),
      assetName: addFormData.assetName,
      direction: addFormData.direction,
      contracts: addFormData.contracts,
      riskBudget: addFormData.riskBudget,
    };

    const newPortfolios = [...portfolio, newPortfolio];
    setPortfolio(newPortfolios);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    const editedPortfolio = {
      id: editPortfolioId,
      assetName: editFormData.assetName,
      direction: editFormData.direction,
      contracts: editFormData.contracts,
      riskBudget: editFormData.riskBudget,
    };

    const newPortfolios = [...portfolio];

    const index = portfolio.findIndex((p) => p.id === editPortfolioId);

    newPortfolios[index] = editedPortfolio;

    setPortfolio(newPortfolios);
    setEditPortfolioId(null);
  };

  const handleEditClick = (event, p) => {
    event.preventDefault();
    setEditPortfolioId(p.id);

    const formValues = {
      assetName: p.assetName,
      direction: p.direction,
      contracts: p.contracts,
      riskBudget: p.riskBudget,
    };

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditPortfolioId(null);
  };

  const handleDeleteClick = (portfolioId) => {
    const newPortfolios = [...portfolio];

    const index = portfolio.findIndex((p) => p.id === portfolioId);

    newPortfolios.splice(index, 1);

    setPortfolio(newPortfolios);
  };



  function extractColumn(jsonList, columnName) {
    var columnArray = [];
    for (var i = 0; i < jsonList.length; i++) {
        columnArray.push(jsonList[i][columnName]);
    }
    return columnArray;
  }

  /**
   * END new code changes
   */







  return (  
    <div className="App">

      <h1>Cycle Futures</h1>

      <label htmlFor="window">Window </label>
        <input
          type="text"
          name="window"
          required="required"
          placeholder={500}
          onChange={updateWindow}
        />
      <br/>
      <br/>
      
      <label htmlFor="interval">Interval </label>
        <select name="interval" required="required" type="text" placeholder="30m" onChange={updateInterval}>
          <option value="30m">30m</option>
          <option value="1d">1d</option>
          <option value="1wk">1wk</option>
        </select>
      <br/>
      <br/>


      <form onSubmit={handleEditFormSubmit}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Direction</th>
              <th>Contracts</th>
              <th>Risk Budget</th>
            </tr>
          </thead>
          <tbody>
            {portfolio.map((p) => (
              <Fragment>
                {editPortfolioId === p.id ? (
                  <EditableRow
                    editFormData={editFormData}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <ReadOnlyRow
                    portfolio={p}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </form>

      <h2>Add a Position</h2>
      <form onSubmit={handleAddFormSubmit}>
        

        <label for="assetName">Asset</label>
          <select name="assetName" required="required" onChange={handleAddFormChange} type="text" placeholder="S&P 500 E-Mini">
            <option value="" disable selected hidden></option>
            <option value="S&P 500 E-Mini">S&P 500 E-Mini</option>
            <option value="Nasdaq 100 E-mini">Nasdaq 100 E-mini</option>
            <option value="US Treasury Bond">US Treasury Bond</option>
            <option value="Gold">Gold</option>
            <option value="Silver">Silver</option>
            <option value="RBOB Gas">RBOB Gas</option>
            <option value="Bitcoin">Bitcoin</option>
            <option value="Ethereum">Ethereum</option>
          </select>


        <label for="direction">Direction</label>
          <select name="direction" required="required" onChange={handleAddFormChange} type="text" placeholder="Long">
            <option value="" disable selected hidden></option>
            <option value="Long">Long</option>
            <option value="Short">Short</option>
          </select>


        <label for="contracts">Contracts</label>
        <input
          type="text"
          name="contracts"
          required="required"
          placeholder="0"
          onChange={handleAddFormChange}
        />


        <label for="riskBudget">Risk Budget</label>
        <input
          type="text"
          name="riskBudget"
          required="required"
          placeholder="0"
          onChange={handleAddFormChange}
        />
      
        <button type="submit">Add</button>

      </form>









      <br/>
      <button onClick={submitPortfolio}>Submit</button>
      <br/>
      <br/>

      <LineChart title='Portfolio Equity' jsonData={metrics.equity}/>
      <br/><br/>
      <LineChart title='Implied Risk Capital' jsonData={metrics.implied_risk_capital}/>
      <br/><br/>
      <LineChart title='Risk Compression' jsonData={metrics.risk_compression}/>
      <br/><br/>
      <LineChart title='Risk Utilization' jsonData={metrics.risk_utilization}/>
  </div>
  )
}

export default App;
