import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function LineChart(props) {

  if (typeof props.jsonData == 'undefined')  {
  } 
  
  else {
    const jsonData = JSON.parse(props.jsonData);
    const xAxis = jsonData.map(d => {
      return d['Date']
      });

    let yAxis = [];

    if (props.title == 'Risk Compression') {
      yAxis = jsonData.map(d => {
        return d['Ratio SD']
        });
        console.log(yAxis)
    } else {
      yAxis = jsonData.map(d => {
        return d['Portfolio']
        });
    }
    

    


    // Set chart options
    const options = {
      
      responsive: true,
      plugins: {
        legend: {
          display:false,
        },
        title: {
          display: true,
          text: props.title,
        },
      },
      elements: {
        point:{
            radius: 0
          }
      }

    };
    
    // Set chart data
    const data = {
      labels: xAxis,
      datasets: [
        {
          label: props.title,
          data: yAxis,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };
    return <Line options={options} data={data} />;
  };
};